import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import intersect from '@alpinejs/intersect'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

// Global
window.Alpine = Alpine

// Components
import './swiper.js'
import './seasonSwitchChanged.js'
import './triggerEnterAnimation.js'

// Inits
Alpine.plugin(persist)
Alpine.plugin(intersect)
Alpine.start()
