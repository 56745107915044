import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

const swiperContainers = document.querySelectorAll('.swiper')
let swiper = null
let swiperOptions = {}
let swiperContainerType = ''
let swiperContainerId = ''

/**
 * Loops through all swiper containers and initializes them
 * with the appropriate options based on the data-swiper-block-type
 *
 * @param swiper    Placeholder for Swiper instance.
 * @param options   Instance options.
 */
const initializeAllSwipers = (swiper, swiperOptions) => {
	swiperContainers.forEach((swiperContainer) => {
		swiperContainerType = swiperContainer.getAttribute(
			'data-swiper-block-type',
		)
		swiperContainerId = swiperContainer.getAttribute('id')

		switch (swiperContainerType) {
			case 'swiper-blue-and-yellow-image-carousel':
				swiperOptions = {
					slidesPerView: 1.3,
					spaceBetween: 15,
					breakpoints: {
						567: {
							slidesPerView: 2.3,
							spaceBetween: 15,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 25,
						}
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				}
				break

			case 'swiper-gallery-carousel':
				swiperOptions = {
					slidesPerView: 1.3,
					spaceBetween: 15,
					breakpoints: {
						567: {
							slidesPerView: 1,
							spaceBetween: 15,
						},
						992: {
							slidesPerView: 2,
							spaceBetween: 35,
						},
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				}
				break

			case 'swiper-ads-reel':
				swiperOptions = {
					slidesPerView: 1,
					pauseOnMouseEnter: true,
					loop: true,
					autoplay: {
						delay: 5000,
					},
					speed: '1500',
					effect: 'cube',
					cubeEffect: {
						shadow: false,
						slideShadows: true,
					},
				}
				break
			
			case 'swiper-featured-blog-article-slider':
				swiperOptions = {
					slidesPerView: 1,
					pauseOnMouseEnter: true,
					loop: true,
					speed: '1500',
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				}
				break

			default:
				break
		}

		swiper = new Swiper(`#${swiperContainerId}`, swiperOptions)
	})
}

if ((swiperContainers != undefined) & (swiperContainers.length !== 0)) {
	initializeAllSwipers(swiper, swiperOptions)
}
