document.addEventListener('alpine:init', () => {
	Alpine.data('triggerEnterAnimation', () => ({
		shown: false,

		trigger: {
			['x-intersect:enter.once']() {
				this.shown = true
			},
			['x-bind:class']() {
				return { 'trigger-animation': this.shown }
			},
		},
	}))
})
