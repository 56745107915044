window.seasonSwitchChanged = function () {

	/* Craft CMS Winter Home Page */
	if (document.body.classList.contains('ski-nm-home-page')) {
		let newUrl = window.location.href
    	newUrl = '/summer'
		window.location.href = newUrl
	}

    /* Craft CMS Summer Home Page */
    if (document.body.classList.contains('ski-nm-summer-home-page')) {
		let newUrl = window.location.href
		newUrl = '/'
        window.location.href = newUrl
    }

	/* Resorts Detection */
	if (window.location.href.includes('/resorts/')) {
		let newUrl = window.location.href

		if (newUrl.includes('/winter/')) {
			newUrl = newUrl.replace('/winter/', '/summer/')
		} else if (newUrl.includes('/summer/')) {
			newUrl = newUrl.replace('/summer/', '/winter/')
		}

		window.location.href = newUrl
	}
}
